
import { ref } from 'vue'
import {projectFirestore,FireStore} from '../../../../firebase/config'
import useStorageWithShrink from '../../../../Methods/useStorageWithShrink'
const {delete_image_by_url} = useStorageWithShrink()

const departments = ref([])

const get_departments_from_db = async() => {
    try{
        const docs = await projectFirestore.collection('Applications')
        .doc('6lr4ioqgzsLABOi1pU5s').collection('Departments').orderBy('name','asc').get()

        departments.value = docs.docs.map(doc => doc.data())
    }catch(err){
        throw new Error(err)
    }
}

const delete_department_from_db = async(dep) => {
    try{
        const docs = await projectFirestore.collection('Applications')
        .doc('6lr4ioqgzsLABOi1pU5s').collection('Departments').doc(dep.uid).delete()

        const index  = departments.value.findIndex(({uid}) => uid == dep.uid)

        if(index != -1){
            departments.value.splice(index,1)
        }

    }catch(err){
        throw new Error(err)
    }
}

const add_new_department_to_db = async(data) => {
    try {
        if (!data || !data.name) {
            throw new Error("Invalid data - Func add_new_department_to_db: 'name' is required");
        }
        const doc = projectFirestore.collection('Applications')
        .doc('6lr4ioqgzsLABOi1pU5s').collection('Departments').doc()

        const obj = {
            name:data.name,
            uid:doc.id,
            managements:[]
        }

        await doc.set(obj)

        departments.value.unshift(obj)

    } catch (err) {
        console.error(err.message);
    }
}

const edit_exist_department = async(dep) => {
    try {
        if (!dep || !dep.name) {
            throw new Error("Invalid data - Func edit_exist_department: 'name' is required");
        }
        await projectFirestore.collection('Applications')
        .doc('6lr4ioqgzsLABOi1pU5s').collection('Departments').doc(dep.uid)
        .set({
            name:dep.name
        },{merge:true})

        const index = departments.value.findIndex(({uid}) => uid == dep.uid)
        if(index != -1){
            departments.value[index] = dep
        }

    } catch (err) {
        console.error(err.message);
    }
}

const add_new_managment_and_update_db = async(dep,new_data) => {
    try {
        await projectFirestore.collection('Applications')
        .doc('6lr4ioqgzsLABOi1pU5s').collection('Departments').doc(dep.uid)
        .set({
            managements:FireStore.FieldValue.arrayUnion(new_data)
        },{merge:true})
    } catch (err) {
        throw new Error(err)
    }
}

const update_managments_in_dep_db = async(dep) => {
    try {
        await projectFirestore.collection('Applications')
        .doc('6lr4ioqgzsLABOi1pU5s').collection('Departments').doc(dep.uid)
        .set({
            managements: dep.managements
        },{merge:true})
    } catch (err) {
        throw new Error(err)
    }
}

const delete_images_from_storage = async (images) => {
    try {
        // Map each image deletion to a promise
        const deletionPromises = images.map(({url}) => 
            delete_image_by_url(url).catch(err => {
                console.error(`Error deleting image ${url}:`, err);
                return null; // Return null to handle the error without failing Promise.all
            })
        );

        // Wait for all deletion promises to resolve
        await Promise.all(deletionPromises);
        console.log('All images processed (successful deletions and errors logged).');
    } catch (err) {
        console.error("An error occurred during image deletions:", err);
        throw new Error(err);
    }
};


export{
    get_departments_from_db,
    add_new_department_to_db,
    add_new_managment_and_update_db,
    update_managments_in_dep_db,
    delete_images_from_storage,
    delete_department_from_db,
    edit_exist_department,
    departments
}