import { ref } from 'vue';
import {projectStorage} from '../firebase/config'

const useStorageWithShrink = () => {
    const error = ref(null);
    const url = ref(null);
    const filePath = ref(null);
  
    const uploadImage = async (file, path) => {
        url.value = null;
        filePath.value = `${path}/${file.name}`;
        const storageReference = projectStorage.ref(filePath.value);
        
        try {
            // upload the file
            const snapshot = await storageReference.put(file);
            url.value = await snapshot.ref.getDownloadURL();
        } catch (err) {
            console.error(err);
            throw new Error(err);
        }
    };

    const delete_image_by_url = async (imageUrl) => {
        const storageReference = projectStorage.refFromURL(imageUrl);
        
        try {
            await storageReference.delete();
        } catch (err) {
            console.error(err);
            throw new Error(err);
        }
    };

    const uploadResizeImage = async (file, path) => {
        error.value = '';
        url.value = '';
        filePath.value = `${path}/${file.name}`;
        const storageReference = projectStorage.ref(filePath.value);

        try {
            // Upload the image
            const snapshot = await storageReference.put(file);
            console.log(snapshot);

            // Wait for the resized URL
            const resizedPattern = /_375x375/; // adjust the pattern as per your naming convention
            url.value = await waitForResizedURL(filePath.value, resizedPattern);
        } catch (err) {
            console.log(err.message);
            error.value = err.message;
        }
    };

    async function waitForResizedURL(path, pattern, interval = 4000, timeout = 120000, startTime = Date.now()) {
        // Define possible resized file paths for different patterns
        const resizedFilePathBeforeExtension = path.replace(/(\.[\w\d_-]+)$/i, '_375x375$1'); // filename_375x375.extension
        const resizedFilePathAfterExtension = path.replace(/(\.[\w\d_-]+)$/i, '$&_375x375');  // filename.extension_375x375
    
        try {
            // Try the first pattern (e.g., filename_375x375.extension)
            let storageReference = projectStorage.ref(resizedFilePathBeforeExtension);
            let url = await storageReference.getDownloadURL();
            
            if (url.includes('_375x375')) {
                return url;
            }
        } catch (err) {
            // Handle the case where the file with this pattern doesn't exist
            if (err.code !== 'storage/object-not-found') throw err;
        }
    
        try {
            // Try the second pattern (e.g., filename.extension_375x375)
            const storageReference = projectStorage.ref(resizedFilePathAfterExtension);
            const url = await storageReference.getDownloadURL();
            
            if (url.includes('_375x375')) {
                return url;
            }
        } catch (err) {
            if (err.code === 'storage/object-not-found' && Date.now() - startTime <= timeout) {
                // Retry if the resized file is not found yet and within timeout
                await new Promise(resolve => setTimeout(resolve, interval));
                return waitForResizedURL(path, pattern, interval, timeout, startTime);
            }
            // Throw any other errors or if timeout exceeded
            throw err;
        }
    
        // If timeout reached, throw an error indicating no resized file was found
        if (Date.now() - startTime > timeout) {
            throw new Error('Timeout waiting for the resized URL.');
        }
    
        // Retry if neither pattern worked
        await new Promise(resolve => setTimeout(resolve, interval));
        return waitForResizedURL(path, pattern, interval, timeout, startTime);
    }
    

    return {
        error,
        url,
        filePath,
        uploadImage,
        delete_image_by_url,
        uploadResizeImage,
    };
};

export default useStorageWithShrink;
